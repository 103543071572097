/**
 * Navigation
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* navigation items */
nav {
	display: block; /* correct `block` display not defined in IE 8/9/10/11. */
}

/* main navigation */
.main-nav {
	grid-area: navigation;
	font-size: 1.25rem;
	line-height: 1;
}

.main-nav ul {
	display: none; /* TODO: fix this for mobile view */
	justify-content: center;
	margin: 0 1rem 1.25rem;
	padding: 0;
	list-style-type: none;
	text-align: center;
}

.main-nav li {
	margin: 0;
	padding: 0;
}

.main-nav a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	margin: 0 0.25rem;
	padding: 1rem 0.75rem;
	color: inherit;
	text-decoration: none;
}

/* highlighted links */
.section-1 #nav-section-1 a,
.section-2 #nav-section-2 a,
.section-3 #nav-section-3 a,
.section-4 #nav-section-4 a,
.section-5 #nav-section-5 a,
.section-6 #nav-section-6 a {
	background-image: inline('line-navigation.png');
	background-repeat: no-repeat;
	background-position: 50% 86%;
	background-size: calc(100% - 1rem) 10px;
}

/* toggle */
.main-nav input[type='checkbox'] {
	position: absolute;
	inset-block-start: 1rem;
	inset-inline-end: 0.5rem;
	inline-size: 1px;
	block-size: 1px;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
}

.main-nav label {
	position: absolute;
	inset-block-start: 1rem;
	inset-inline-end: 0.5rem;
	z-index: 101;
	inline-size: 3rem;
	block-size: 3rem;
	cursor: pointer;
}

.main-nav label span,
.main-nav label span::before,
.main-nav label span::after {
	position: absolute;
	border-radius: 3px;
	block-size: 3px;
	background-color: var(--color-blue);
	transition-property: transform;
	transition-duration: 0.2s;
	transition-timing-function: ease;
}

.main-nav label span {
	display: block;
	inset-block-start: 50%;
	inset-inline-start: 25%;
	inline-size: 50%;
	margin-block-start: -1.5px;
}

.main-nav label span::before,
.main-nav label span::after {
	content: '';
	display: block;
	inline-size: 100%;
}

.main-nav label span::before {
	inset-block-start: -7px;
	transition:
		top 0.1s 0.25s ease-in,
		opacity 0.1s ease-in;
}

.main-nav label span::after {
	inset-block-end: -7px;
	transition:
		bottom 0.1s 0.25s ease-in,
		transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.main-nav input:checked ~ label span {
	transform: rotate(225deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.main-nav input:checked ~ label span::before {
	inset-block-start: 0;
	opacity: 0;
	transition:
		top 0.1s ease-out,
		opacity 0.1s 0.12s ease-out;
}

.main-nav input:checked ~ label span::after {
	inset-block-end: 0;
	transform: rotate(-90deg);
	transition:
		bottom 0.1s ease-out,
		transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* navigation via toggle */
.main-nav input:checked ~ ul {
	display: block;
}

@media (width >= 540px) {
	.main-nav label {
		inset-inline-end: 1rem;
	}
}

@media (width >= 768px) {
	.main-nav input:checked ~ ul {
		display: flex;
	}
}

@media (width >= 1024px) {
	.main-nav input,
	.main-nav label {
		display: none;
	}

	.main-nav ul {
		display: flex;
		justify-content: flex-end;
		margin-block-start: 1.25rem;
	}
}

@media (width >= 1110px) {
	.main-nav ul {
		margin-inline-end: 2.5rem;
	}

	.main-nav a:is(:link, :visited, :hover, :focus, :active) {
		padding-inline: 1rem;
	}
}

@media (prefers-reduced-motion: reduce) {
	.main-nav label span,
	.main-nav label span::before,
	.main-nav label span::after {
		transition: none;
	}
}

/* admin navigation */
.admin-nav {
	display: flex;
	position: absolute;
	inset-block-start: -1.5rem;
	inset-inline-end: 4rem;
	margin: 0;
	padding: 0;
	font-size: 0.75rem;
	color: var(--color-white);
	list-style-type: none;
}

.admin-nav a:is(:link, :visited, :hover, :focus, :active) {
	display: flex;
	margin-inline-start: 1rem;
	color: inherit;
	font-weight: normal;
}

.admin-nav svg {
	margin: 0.125em 0.25em 0 0;
	inline-size: 1em;
	block-size: 1em;
}

.admin-nav form {
	margin: 0;
}

.admin-nav .link-button {
	display: inline-block;
	margin: 0 0 0 1rem;
	color: inherit;
	font-weight: normal;
}

/* hidden navigation */
@media print {
	.main-nav,
	.admin-nav {
		display: none;
	}
}
