/**
 * Headlines
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* headlines */
h1,
.like-h1 {
	margin: 4.5rem 0 1rem;
	font-weight: 600;
	font-size: 1.75em;
	line-height: 1.25;
	text-align: center;
	hyphens: auto;
}

h1 > span,
.like-h1 > span {
	padding: 0 5px 10px;
	background-image: inline('line-headline.png');
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: 100% 20px;
}

h1:first-child {
	margin-block-start: 1rem;
}

h2 {
	margin: 4.5rem 0 1rem;
	font-weight: 600;
	font-size: 1.5em;
	line-height: 1.25;
	text-align: center;
}

h3,
.like-h3 {
	margin: 2rem 0 1rem;
	font-weight: 600;
	font-size: 1em;
	line-height: 1.25;
	text-align: start;
}

h4,
.like-h4 {
	margin: 1rem 0;
	font-weight: 600;
	font-size: inherit;
	line-height: inherit;
	text-align: start;
}

/* abbreviations in headlines */
:is(h1, h2, h3, h4) abbr[title] {
	border-block-end: 0;
}

@media (width >= 540px) {
	h1,
	.like-h1 {
		font-size: 2.25em;
	}

	h2 {
		font-size: 1.75em;
	}

	h3,
	.like-h3 {
		font-size: 1.25em;
	}
}

/* page breaks */
@media print {
	h1,
	h2,
	h3,
	h4 {
		page-break-inside: avoid;
	}
}
